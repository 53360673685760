var typedObj=function(type) {
  return function(config) {
    if (config)
      config._type=type;
    return config;
  }
}

var slotList=typedObj("slotList");



var prioritySort=function(a, b) {
  if ((a.priority || 0) < (b.priority || 0)) return -1;
  if ((a.priority || 0) > (b.priority || 0)) return 1;
  return 0;
}

var mergeAuto=function(result, value) {
  if (typeof(result)!="object")
    return value;

  if (Array.isArray(result) && Array.isArray(value)) {
    if (result._type=='slotList' && value._type=='slotList') {
      for(var newItem of value) {
        var existingIndex=result.findIndex(item=>item.id && item.id==newItem.id);
        if (existingIndex==-1)
          result.push(newItem);
        else {
          if (newItem._deleted)
            result.splice(existingIndex, 1);
          else
            result[existingIndex]=mergeAuto(result[existingIndex], newItem);
        }
      }
      result.sort(prioritySort);
    } else
      result.push(...value);
  } else {
    for(var field in value)
      result[field]=mergeAuto(result[field], value[field]);
  }
  return result;
}

var mergeManifests=function(...manifests) {
  var result={};
  var sortedManifests=manifests.slice().sort(prioritySort);
  for(var manifest of sortedManifests) {
    if (typeof(manifest)!="object")
      continue;
    for(var field in manifest) {
      if (!(field in result))
        result[field]=manifest[field];
      else if (typeof(manifest[field])!="object")
        result[field]=manifest[field];
      else
        result[field]=mergeAuto(result[field], manifest[field]);
    }
  }
  return result;
}


export { slotList, mergeManifests };
