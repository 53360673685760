<template>
  <section class="toast">
    <ul>
      <li v-for="toast in toasts" :class="'toast-'+(toast.type || 'default')+' active'">
        <div class="toast-content">
          {{ toast.text }}
        </div>
        <span class="close"></span>
        <a class="close" v-on:click.prevent="toast.dismiss" href=""></a>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  computed: {
    toasts() {
      return this.stores.toasts.toasts;
    },
  },
}

</script>
