import { defineStore } from 'pinia'
import Cookies from 'js-cookie'

function ActiveRecord(type, item) {
  this._type=type;
  for(var field in item || {})
    this[field]=item[field];
}
ActiveRecord.prototype.parseFields=function(showFields) {
  var showFieldsLen=showFields.length;
  var tokens=[];
  var lastTokenType=null;
  for(var i=0;i<showFieldsLen;i++) {
    var char=showFields[i];
    var charCode=showFields.charCodeAt(i);
    var incl=(charCode>=65 && charCode<=90) ||
              (charCode>=97 && charCode<=122) ||
              ["_", "-","*","{","}",","].indexOf(char)!=-1;
    if (!incl)
      continue;
    if (lastTokenType!="identifier") {
      tokens.push(char);
      lastTokenType="other";
      if ((charCode>=65 && charCode<=90) ||
          (charCode>=97 && charCode<=122) ||
          ["_", "-","*"].indexOf(char)!=-1)
        lastTokenType="identifier";
    } else if (lastTokenType=="identifier") {
      if ((charCode>=65 && charCode<=90) ||
          (charCode>=97 && charCode<=122) ||
          ["_", "-","*"].indexOf(char)!=-1)
        tokens[tokens.length-1]+=char;
      else {
        tokens.push(char);
        lastTokenType="other";
      }
    }
  }
  while(tokens.length>1) {
    var lastCount=tokens.length;
    var lastOpen=null;
    for(var i in tokens) {
      var token=tokens[i];
      if (Array.isArray(token))
        continue;
      if (token=="{")
        lastOpen=i;
      if (token=="}") {
        if (lastOpen===null)
          return null;
        var inner=tokens.slice(lastOpen*1+1, i*1).filter(item=>item!=",");
        var newInner=[];
        for(var innerItem of inner) {
          if (typeof(innerItem)=="string")
            newInner.push({field: innerItem});
          else if (newInner.length)
            newInner[newInner.length-1].sub=innerItem;
        }
        tokens.splice(lastOpen*1, i-lastOpen+1, newInner);
        break;
      }
    }
    if (tokens.length>=lastCount)
      break;
  }
  return tokens[0] || null;
}
ActiveRecord.prototype.filterFields=function (data, fields) {
  var result={};
  if (typeof(fields)=="string")
    fields=this.parseFields(fields);
  if (!fields)
    return data;
  for(var field in data) {
    var value=data[field];
    if (field=="id" || field=="uuid") {
      result[field]=value;
      continue;
    }
    var fieldDesc=fields.find(item=>item.field==field);
    if (!fieldDesc)
      continue;
    if (typeof(value)!="object" || !fieldDesc.sub)
      result[field]=value;
    else if (Array.isArray(value)) {
      result[field]=value.map(item=>this.filterFields(item, fieldDesc.sub));
    } else {
      result[field]=this.filterFields(value, fieldDesc.sub);
    }
  }
  return result;
}
ActiveRecord.prototype.save=async function(fields) {
  var blacklist=["_type", "id", "uuid"];
  var data={};
  for(var i in this) {
    if (blacklist.indexOf(i)==-1)
      data[i]=this[i];
  }
  if (fields)
    data=this.filterFields(data, fields);
  if (this.id)
    var retData=(await api.doUpdate(this._type, this.id, data, true)).item;
  else
    var retData=(await api.doCreate(this._type, data, true)).item;
  for(var field in retData || {})
    this[field]=retData[field];
}
ActiveRecord.prototype.delete=async function() {
  if (this.id)
    return await api.doDelete(this._type, this.id);
}

var api={
  objQuery(data, path) {
    var result=[];
    path=path || [];
    for(var key in data) {
      if (Array.isArray(data)) {
        result=[...result, ...this.objQuery(data[key], [...path, key])];
      } else if (typeof(data)=="object") {
        result=[...result, ...this.objQuery(data[key], [...path, key])];
      } else {
        var actualKey=[...path, key].map((el,index)=>index?'['+el+']':el).join("");
        result.push([actualKey, data[key]]);
      }
    }
    return result;
  },
  async request(method, path, data) {
    if (method=="GET" && data) {
      path+="?"+(new URLSearchParams(this.objQuery(data)).toString());
    }
    try {
      var authorization={};
      var token=Cookies.get("authLogin");
      if (token)
        authorization.Authorization="Bearer "+token;
      var req=await fetch(appEnv.apiRoot+path, {
        method,
        headers: {
          ...authorization,
          ...(method!="GET" && method!="DELETE"?{"Content-Type": "application/json"}:{}),
        },
        cache: 'no-cache',
        ...(method!="GET" && method!="DELETE"?{body: JSON.stringify(data)}:{}),
      });
      try {
        var body=await req.text();
        if (req.ok)
          return JSON.parse(body);
        else
          return {...JSON.parse(body), status: req.status};
      } catch(e) {
        return {status: req.status, error: body};
      }
    } catch(e) {
      return {error: 'network'};
    }
  },
  async upload(path, file, field) {
    try {
      var formData=new FormData();
      formData.append("file", file);
      if (field)
        formData.append("field", field);
      var req=await fetch(appEnv.apiRoot+path, {
        method: "POST",
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
        cache: 'no-cache',
        body: formData,
      });
      try {
        var body=await req.text();
        if (req.ok)
          return JSON.parse(body);
        else
          return {...JSON.parse(body), status: req.status};
      } catch(e) {
        return {status: req.status, error: body};
      }
    } catch(e) {
      console.log(e);
      return {error: 'network'};
    }
  },
  async list(entity, where, fields, sort, limit, start, getCount) {
    var args={};
    if (where)
      args.q=where;
    if (fields)
      args.g=fields;
    if (sort)
      args.o=sort.join(",");
    if (limit)
      args.l=limit;
    if (start)
      args.s=start;
    var result=(await this.request("GET", entity, args));
    if (getCount)
      return {count: result.count, items: (result.items || []).map(
      (item)=>new ActiveRecord(entity, item))};
    return (result.items || []).map(
      (item)=>new ActiveRecord(entity, item));
  },
  async get(entity, id, fields) {
    var extra=fields?"?g="+encodeURIComponent(fields):"";
    var item=(await this.request("GET", entity+"/"+id+extra)).item;
    if (item)
      return new ActiveRecord(entity, item);
    return null;
  },
  create(entity) {
    return new ActiveRecord(entity);
  },
  async doDelete(entity, id) {
    return await this.request("DELETE", entity+"/"+id);
  },
  async doUpdate(entity, id, data, returnItem) {
    return await this.request("PUT", entity+"/"+id, {item: data, ...(returnItem?{"return": true}:{})});
  },
  async doCreate(entity, data, returnItem) {
    return await this.request("POST", entity, {item: data, ...(returnItem?{"return": true}:{})});
  },
  async doLogin(username, authdata) {
    return await this.request("POST", "login", {group: "Customer", username, authdata});
  },
  stores: {},
  async storeFor(entity) {
    if (!this.stores[entity]) {
      this.stores[entity]=defineStore(entity, {
        state: {
          items: [],
        },
        actions: {
          async all() {
            this.items=(await api.list(entity));
          }
        },

      });
    }
    return this.stores[entity];

  }
}

var storeFor=api.storeFor.bind(api);

export default api;
export {storeFor};
