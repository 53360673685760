<template>
    <div class="login-form">
      <h5>Log In</h5>
      <form v-if="mode=='form'" @submit.prevent="doLogin">
        <div class="form-field">
          <label>Email</label>
          <input placeholder="you@company.com" type="email" required="" v-model="form.email">
        </div>
        <div class="form-field">
          <label>Password</label>
          <input type="password" required="" v-model="form.password">
        </div>
        <p class="error" v-if="error">
          {{ error }}
        </p>
        <a href="/password/forgot" class="link">Forgot password?</a>
        <button class="button-primary">Login</button>
        <a href="/register" class="button-secondary">No account? Create one</a>
      </form>
    </div>

</template>

<script>
import pubApi from '@/Util/pubapi'
import api from '@/Util/api'
import Cookies from 'js-cookie'

export default {
  data: ()=>({
    mode: 'form',
    error: null,
    form: {
      email: "",
      password: "",
    },
  }),
  methods: {
    async doLogin() {
      var response=await api.doLogin(this.form.email, {password: this.form.password});
      this.error=response.error || null;
      if (response.success) {
        Cookies.set('authLogin', response.token);
        location.href="/profile";
      } else {
        this.error="Invalid username or password";
      }
    },
  },
}
</script>
