<template>
<div v-if="cart && cart.products">
  {{ cart.products.length }} products
  <a href="/cart">View cart</a>
</div>
</template>

<script>
import api from '@/Util/api';

export default {
  data: ()=>({
    cart: null,
  }),
  methods: {
    async loadCart() {
      this.cart=(await api.list("Cart"))[0];
      if (!this.cart) {
        this.cart=api.create("Cart");
        await this.cart.save();
      }
    },
    async addProduct(product) {
      var existingProduct=this.cart.products.find(prod=>prod.product?.uuid==product.uuid);
      if (existingProduct) {
        existingProduct.quantity++;
      } else {
        this.cart.products.push({
          product: product.uuid,
          quantity: 1,
        });
      }
      await this.cart.save("{products}");
      this.loadCart();
//       this.products.push(product);
    },
  },
  created() {
    this.loadCart();
    window.shopCart=this;
  }
}
</script>
