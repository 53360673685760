// import nav from '@/nav';
import { mergeManifests } from '@/Util/manifest';


export default {
  initNav({stores}) {
    if (stores) {
//       stores.nav.items.push(...nav.items);
    }
  },
  initPlugins({app, stores, components}) {
    var plugins=require.context("@plugins/", true, /^\.\/[^/]+\/public\/plugin\.js$/);
    for(var plugin of plugins.keys())
      plugins(plugin);

    var manifests=require.context("@plugins/", true, /^\.\/[^/]+\/public\/plugin-manifest\.js$/);
    var pluginManifests=[];
    for(var key of manifests.keys())
      pluginManifests.push(manifests(key).default);
    stores.config.config=mergeManifests(
      require("@/../public-manifest.js").default,
      ...pluginManifests,
    );
    var vueComponents=require.context("@plugins/", true, /^\.\/[^/]+\/public\/.*\.vue$/);
    for(var key of vueComponents.keys()) {
      var plugin=key.match(/^\.\/([^/]+)/)[1];
      var component=vueComponents(key).default;
      var componentName=key.replace(/\.vue|.*\//g, "");
      var actualKey=(plugin?plugin+":":"")+componentName;
      components[actualKey]=component;
      app.component(actualKey, component);
      var actualKey2=(plugin?plugin+":":"")+componentName.substring(0,1).toLowerCase()+componentName.substring(1);
      components[actualKey2]=component;
      app.component(actualKey2, component);
    }

    for(var exported of stores.config.config.exported || []) {
      var targetComponentName=exported.replace(/^[^:]+:/, "");
      app.component(targetComponentName, components[exported]);
      components[targetComponentName]=components[exported];
    }


  },
}
