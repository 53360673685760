import { defineStore } from 'pinia'

export default defineStore("toasts", {
  state: ()=>({
    toasts: [],
    id: 0,
  }),
  getters: {
    count() {
      return this.toasts.length;
    },
  },
  actions: {
    newToast(text, type) {
      // type: 'ok', 'warn', 'fail' or null
      var id=++this.id;
      var dismiss=()=>{
        var toastIndex=this.toasts.findIndex(toast=>toast.id==id);
        if (toastIndex!=-1)
          this.toasts.splice(toastIndex, 1);
        clearTimeout(cancelTimeout);
      }
      var cancelTimeout=setTimeout(dismiss, 5000);
      this.toasts.push({text, type, id, dismiss, cancelTimeout});
    }
  },
});
