<template>
    <div class="login-form">
      <h5>Register</h5>
      <form @submit.prevent="doRegister" v-if="mode=='form'">
        <div class="form-field">
          <label>Full name</label>
          <input placeholder="Jane Smith" type="text" required="" v-model="form.firstName">
        </div>
        <div class="form-field">
          <label>Email</label>
          <input placeholder="you@company.com" type="email" required="" v-model="form.email">
        </div>
        <div class="form-field">
          <label>Password</label>
          <input type="password" required="" v-model="form.password">
        </div>
        <div class="form-field">
          <label>Confirm Password</label>
          <input type="password" required="" v-model="form.confirm">
        </div>
        <p class="error" v-if="error">
          {{ error }}
        </p>
        <button class="button-primary">Register</button>
        <a href="/login" class="button-secondary">Already have an account? Log in here</a>
      </form>
      <div class="message" v-if="mode=='success'">
        Thank you for registering!
        <a href="/login" class="button-secondary">You can now log in here</a>
      </div>
    </div>
</template>
<script>
import pubApi from '@/Util/pubapi'
import api from '@/Util/api'

export default {
  data: ()=>({
    error: null,
    form: {
      firstName: "",
      email: "",
      password: "",
      confirm: "",
    },
    loading: false,
    mode: "form",
  }),
  methods: {
    async doRegister() {
      if (this.form.password!=this.form.confirm)
        return this.error="Password confirmation doesn't match";
      this.error=null;
      await api.doCreate("User", {
        ...this.form,
        username: this.form.email,
        tokens: [
          {type: "password", token: this.form.password},
        ],
        group: "Customer",
      });
      this.mode='success';
    },
  },
}
</script>
