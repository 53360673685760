import { createApp } from 'vue'
import { createPinia } from 'pinia'
import RootApp from './App.vue'
import Api from './Util/api'
import PubApi from './Util/pubapi'
import init from './init'

window.appApi=Api;
window.appPubApi=PubApi;
window.appEnv={
  apiRoot: "/api/",
  pubApiRoot: "/v1/",
};

var VueApp=createApp(RootApp)


var VueComponents = {};
var VueStores = {};
var vueComponentList = [
  require.context(".", true, /.+\.vue$/),
];

for(let vueComponentListItem of vueComponentList)
  for(let key of vueComponentListItem.keys()) {
    let actualKey=key.replace(/\.vue|.*\//g, "");
    let actualComponent=vueComponentListItem(key).default;
    VueComponents[actualKey]=actualComponent;
    VueApp.component(actualKey, actualComponent);
  }

var Pinia=createPinia({});
VueApp.use(Pinia);

var storeList = [
  require.context("./Stores", false, /.+\.js$/),
];
for(let storeListItem of storeList) {
  for(let key of storeListItem.keys()) {
    let actualKey=key.replace(/\.js|.*\//g, "");
    let actualExport=storeListItem(key).default;
    VueStores[actualKey]=actualExport();
  }
}
VueApp.mixin({data: ()=>({
  stores: VueStores,
})});


for(var initMethod in init) {
  init[initMethod]({app: VueApp, stores: VueStores, components: VueComponents});
}


VueApp.mount(document.querySelector('#app'))


var dynamicElements=document.querySelectorAll("[vue-dyn]");
for(var element of dynamicElements) {
  var elementApp=createApp({});
  elementApp.use(Pinia);
  for(var component in VueComponents)
    elementApp.component(component, VueComponents[component]);
  elementApp.mixin({data: ()=>({
    stores: VueStores,
  })});
  elementApp.mount(element);
}

