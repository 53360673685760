<template>
<div v-if="cart">
  {{ cart.products.length }} products
  <ul>
    <template v-for="product in cart.contents">
      <li v-if="product.bundle">
        {{ product.bundle?.title }}
        {{ product.bundle?.price }}
      </li>
      <li v-else>
        {{ product.product?.title }}
        {{ product.quantity }}
        {{ product.product?.price }}
        {{ computedPrice(product) }}
      </li>
    </template>
  </ul>
</div>
</template>

<script>
import api from '@/Util/api';

export default {
  data: ()=>({
    cart: null,
  }),
  methods: {
    async loadCart() {
      this.cart=(await api.list("Cart"))[0];
      if (!this.cart) {
        this.cart=api.create("Cart");
        await this.cart.save();
      }
    },
    async addProduct(product) {
      var existingProduct=this.cart.products.find(prod=>prod.product?.uuid==product.uuid);
      if (existingProduct) {
        existingProduct.quantity++;
      } else {
        this.cart.products.push({
          product: product.uuid,
          quantity: 1,
        });
      }
      await this.cart.save("{products}");
      this.loadCart();
//       this.products.push(product);
    },
    computedPrice(product) {
      var price=product.product?.price;
      for(var discount of product.discounts || []) {
        price=Math.min(price, discount[1] || price);
      }
      return price;
    },
  },
  created() {
    this.loadCart();
    window.shopCart=this;
  }
}
</script>
