<template>
<button @click.prevent="addToCart">Add to cart</button>
</template>

<script>
export default {
  props: ["product"],
  methods: {
    addToCart() {
      window.shopCart.addProduct(this.product);
    },
  }
}
</script>

