import { defineStore } from 'pinia'

export default defineStore("config", {
  state: ()=>({
    slots: {},
    forms: {},
    config: {},
  }),
  getters: {
  },
  actions: {
  },
});
