<template>
<iframe :src="iframeUrl" :key="iframeUrl" allowfullscreen></iframe>
</template>

<script>
import $ from 'jquery';
export default {
  data: ()=>({
    videoId: null,
  }),
  computed: {
    iframeUrl() {
      if (!this.videoId)
        return null;
      var matches=this.videoId.match(/^video:(.*)/);
      if (matches)
        return "/demo?player="+matches[1];
      var matchesVimeo=this.videoId.match(/^vimeo:(.*)/);
      if (matchesVimeo)
        return "https://player.vimeo.com/video/"+matchesVimeo[1];
    },
  },
  mounted() {
    var that=this;
    $("a[data-videoid]").click(function(e) {
      e.preventDefault();
      that.videoId="video:"+$(this).attr("data-videoid");
    });
    $("a[data-vimeoid]").click(function(e) {
      e.preventDefault();
      that.videoId="vimeo:"+$(this).attr("data-vimeoid");
    });
    $("a[data-autoselect]").click();
  },
}
</script>
